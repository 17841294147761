@import 'styles/mixins';
@import 'styles/variables';

.schedule {
  margin-top: 50px;
  display: grid;
  overflow: hidden;
  max-width: 100%;
  grid-template-rows: 1fr 2fr 2fr 2fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: "day1 day2 day3 day4 day5"
                       ".    .     hack hack hack"
                       "ecos ecos .    .    .   "
                       "boun boun boun .    .   ";
  column-gap: 10px;
  row-gap: 20px;
  
  @include phonesAndTablet {
    overflow: auto;
    row-gap: 10px;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 73px 112px 112px 112px;
    grid-template-areas: "day1 .    ecos boun"
                         "day2 .    ecos boun"
                         "day3 hack .    boun"
                         "day4 hack .    .   "
                         "day5 hack .    .   ";
  }
  @include smallPhone {
    grid-template-columns: 70px 90px 90px 90px;
  }
  .day1 {
    grid-area: day1;
  }
  .day2 {
    grid-area: day2;
  }
  .day3 {
    grid-area: day3;
  }
  .day4 {
    grid-area: day4;
  }
  .day5 {
    grid-area: day5;
  }
  .hackathon {
    grid-area: hack;
    background-color: #f37737;
  }
  .ecosystem {
    grid-area: ecos;
    background-color: #b675cd;
  }
  .bounty {
    grid-area: boun;
    background-color: #2162f0;
  }

  .weekday {
    border: 1px solid rgba(51, 51, 51, 0.2);
    border-radius: 18px;
    font-family: var(--time-font-family);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 10px;
    font-size: 0.9rem;
    .weekday__short {
      display: none;
    }
    @include phonesAndTablet {
      padding: 0;
      font-size: 0.8rem;
      flex-direction: column;

      .weekday__comp {
        display: none;
      }
      .weekday__short {
        display: initial;
        
      }
    }
    @include smallPhone {
      font-size: 0.6rem;
    }
  }

  .bar {
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 35px 50px;
    text-align: left;
    color: var(--hero-color);
    font-weight: 300;
    font-size: 0.86rem;
    .title {
      font-weight: 600;
    }
    @include phonesAndTablet {
      padding: 0;
      font-size: 0.65rem;
      text-align: center;
      align-items: center;
      justify-content: flex-start;

      .title {
        background-color: rgba(255, 255, 255, 0.2);
        width: 100%;
        padding: 10px;
        height: 72px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      span {
        display: block;
        padding: 10px;
        font-weight: 400;
      }
    }
    @include smallPhone {
      font-size: 0.5rem;
    }
  }
}