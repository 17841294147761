.prizes-page {
  background-image: url("/assets/images/faq-bg.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100vh;
  h1 {
    color: #2162ee;
    font-size: 2.5em;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  .sub-text {
    // font-size: 0.8rem;
    margin-bottom: 40px;
    text-align: left;
  }
  .prizes-content {
    display: block;
    text-align: left;
    h1 {
      text-align: center;
    }
    h2 {
      margin-top: 50px;
    }
    h3 {
      font-size: 1em;
      white-space: normal;
    }
  }
  ul {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}