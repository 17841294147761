@import 'styles/mixins';
@import 'styles/variables';

$main-gap: 45px;
$arrow-size: 78px;

.content-section {
  margin: 100px auto;
  max-width: var(--page-width);
  padding: 0 var(--content-padding);
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: $main-gap;

  .centred {
    text-align: center;
  }

  @include phonesAndTablet {
    flex-direction: column;
    margin: 80px auto;
    padding: 0 15px;
    .arrow {
      display: none;
    }
    & > h3 {
      max-width: 100%;
    }
  }

  &.columns {
    flex-direction: column;
    gap: 5px;
  }

  &.first {
    margin-top: 240px;
    @include phonesAndTablet {
      margin-top: 160px;
    }
  }
  /* &.last {
    margin-bottom: 240px;
    @include phonesAndTablet {
      margin-bottom: 160px;
    }
  } */
  & > h3 {
    min-width: calc(45% - #{$main-gap} - (#{$arrow-size} / 2));
    font-size: min(5vw, 3.2rem);
    margin: 0;
    display: block;
    @include phonesAndTablet {
      font-size: max(1.5rem, 5vw);
      white-space: normal;
      & > br {
        display: none;
      }
    }
  }
  & > .title-content {
    min-width: calc(45% - #{$main-gap} - (#{$arrow-size} / 2));
    & > h3 {
      font-size: min(5vw, 3.5rem);
      white-space: nowrap;
      margin: 0;
      display: block;
      @include phonesAndTablet {
        font-size: max(1.5rem, 5vw);
        white-space: normal;
        & > br {
          display: none;
        }
      }
    }
  }

  & > h4 {
    margin: 0;
    font-weight: 400;
    @include allPhones {
      font-size: 1rem;
    }
  }

  .arrow {
    background-image: url('/assets/images/content-arrow.svg');
    background-size: contain;
    background-repeat: no-repeat;
    div {
      width: $arrow-size;
      height: 28px;
    }
  }

  .section-p {
    text-align: left;
  }

  .form-banner {
    position: initial;
    max-width: 100%;
    width: 100%;

    transform: none;
    box-shadow: 0px 0px 25px rgb(33 98 238 / 50%);
  }
}