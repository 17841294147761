@import 'styles/mixins';
@import 'styles/variables';

footer {
  width: 100%;
  background-color: var(--footer-background);
  position: relative;
  z-index: 1;
  font-size: var(--font-size);
  color: var(--hero-color);
  .footer-content {
    width: 100%;
    max-width: var(--page-width);
    padding: 0 40px;
    margin: auto;
    .footer-columns {
      min-height: 400px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include allPhones {
        flex-direction: column;
        justify-content: center;
        min-height: auto;
        padding: 130px 0 60px 0;
      }
      
      .left {
        text-align: left;
        flex: 1;
        font-size: .74rem;
        @include allPhones {
          flex: 0;
          text-align: center;
          font-weight: 400;
          img {
            width: 220px !important;
          }
        }
      }

      .right {
        text-align: right;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-direction: column;
        p {
          font-size: .74rem;
        }
        @include allPhones {
          flex: 0;
          text-align: center;
          align-items: center;
          margin-top: 40px;

          p {
            font-size: .62rem;
          }
        }

        .buttons {
          display: flex;
          gap: 20px;
        }

        .icon-a {
          width: 45px;
          height: 45px;
          cursor: pointer;
          background-size: contain;
          background-repeat: no-repeat;
          color: transparent;
          overflow: hidden;
          &.twitter {
            background-image: url("/assets/images/twitter.svg");
          }
          &.telegram {
            background-image: url("/assets/images/telegram.svg");
          }
          &.discord {
            background-image: url("/assets/images/discord.svg");
          }
        }
      }
    }
    .copyright {
      font-size: 10px;
      padding: 30px 0;
      border-top: 1px solid var(--hr-color);
    }
  }
}