@import 'styles/mixins';

*, *::before, *::after {
  box-sizing: border-box;
}

html {
  font-size: var(--font-size);
  font-family: var(--font-family);
  background: var(--background-color);
  color: var(--font-color);
  line-height: 1.4;
}

body {
  margin: auto;
  text-align: center;
}

h1 {
  margin: 0 0 2rem;
}

.apply-button {
  display: inline-flex;
  text-decoration: none;
  flex-direction: column;
  border: none;
  background-color: var(--hero-color);
  color: #2162ee;
  padding: 14px 0 10px 15px;
  border-radius: 30px 0 0 30px;
  min-width: 270px;
  position: relative;
  font-weight: 400;
  height: 50px;
  font-size: min(3.5vw, 18px);
  cursor: pointer;
  box-shadow: var(--box-shadow);
  justify-content: center;
  align-items: center;
  @include tablet {
    min-width: 270px;
    margin-top: 30px;
  }
  @include allPhones {
    min-width: 55vw;
    margin-top: 30px;
    margin-left: 0;
  }

  &::before,
  &::after {
    position: absolute;
    content: "";
    right: 0;
    top: 13px;
    width: 24px;
    height: 23px;
    background-color: var(--hero-color);
    border-radius: 2px;
    transform: translateX(50%)  scale(1, 1.5) rotate(45deg); 
    z-index: 2;
  }
  &::before {
    z-index: -1;
    box-shadow: 2px 0px 6px rgb(53 53 53 / 25%);
  }

  .subtitle {
    font-size: .7em;
  }

  &.alt {
    background-color: #2162ee;
    color: var(--hero-color);
    &::before,
    &::after {
      background-color: #2162ee;
    }
  }
}

