@import 'styles/mixins';
@import 'styles/variables';

.tracks {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  min-width: 570px;
  @include allPhones {
    min-width: 100%;
    flex-direction: column;
    align-items: center;
  }
}

.track {
  flex: 1;
  min-width: calc(50% - 15px);
  background-color: var(--track-bg-color);
  padding: 90px 30px 30px 30px;
  text-align: left;
  position: relative;
  border-radius: 15px;
  h4 {
    font-weight: bold;
  }
  @include allPhones {
    min-width: 0;
    max-width: 300px;
    width: 100%;
  }

  p {
    font-size: 0.8em;
  }

  img {
    left: 15px;
    top: -20px;
    position: absolute;
  }
}