@import 'styles/mixins';
@import 'styles/variables';

.prizes {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
  row-gap: 40px;
  margin-top: 50px;
  @include allPhones {
    row-gap: 40px;
    column-gap: 40px;
    gap: 40px;
  }

  .prize {
    width: 228px;
    box-shadow: 0px 0px 25px rgb(33 98 238 / 30%);
    border-radius: 15px;
    overflow: hidden;

    .prize-title {
      text-transform: uppercase;
      font-size: 0.75em;
      padding: 10px 20px;
      height: 71px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: var(--hero-color);
      &.title1 {
        background: linear-gradient(90deg, #f57432 0%, #d26753 100%);
      }
      &.title2 {
        background: linear-gradient(90deg, #cc6558 0%, #7c48a4 100%);
      }
      &.title3 {
        background: linear-gradient(90deg, #7545ab 0%, #342ee8 100%);
      }
      &.title4 {
        background: linear-gradient(90deg, #312deb 0%, #2a2af1 100%);
      }
    }
    .prize-content {
      font-size: 0.8em;
      font-weight: 600;
      height: 280px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-direction: column;
      padding: 0 20px;
    }
  }
}

.prizes-subtitle {
  font-size: 1.5em;
}
