@import 'styles/mixins';

.venue {
  display: flex;
  text-align: left;
  gap: 40px;
  margin-top: 70px;
  justify-content: space-between;
  .link-image,
  img {
    max-width: 50%;
  }
  .link-image {
    margin-top: 20px;
    img {
      max-width: 100%;
    }
  }
  ol {
    margin: 10px 0;
    font-size: .9em;
    ul {
      margin: 10px 0;
    }
  }
  @include phonesAndTablet {
    margin-top: 0;
    text-align: center;
    flex-direction: column-reverse;
    .link-image,
    img {
      max-width: 100%;
    }
    .link-image {
      margin-top: 40px;
    }
    ol { text-align: left;}
  }
  iframe {
    border: none;
  }
}