@import 'styles/mixins';
@import 'styles/variables';

$button-height: 21px;
$line-thickness: 3px;
$animation-duration: 0.3s;

.app-menu {
  overflow: hidden;
  .page-nav {
    display: flex;
    gap: 20px;
  }
  .mobile {
    display: none;
  }
  @include allPhones {
    .mobile {
      display: block;
    }
    .desktop {
      position: absolute;
      height: auto;
      width: 100%;
      left: 0;
      top: 0;
      background-color: var(--background-color);
      display: flex;
      flex-direction: column;
      padding: 100px 15px 30px 15px;
      transform: translateY(-100%);
      transition: transform $animation-duration ease-in-out calc(#{$animation-duration} / 2), box-shadow calc(#{$animation-duration} / 2) linear;
      box-shadow: none;
      & > * {
        opacity: 0;
        transition: opacity calc(#{$animation-duration} / 2) linear;
      }
      &.open {
        transform: translateY(0);
        transition: transform $animation-duration ease-in-out, box-shadow calc(#{$animation-duration} / 2) linear calc(#{$animation-duration} / 2);
        box-shadow: var(--box-shadow);
        & > * {
          opacity: 1;
          transition: opacity calc(#{$animation-duration} / 2) linear $animation-duration;
        }
      }
    }
    .menu-button {
      display: flex;
      position: relative;
      background-color: transparent;
      border: none;
      width: 35px;
      height: $button-height;
      justify-content: center;
      align-items: center;
      padding: 0;
      cursor: pointer;
      z-index: 10;

      div {
        width: 100%;
        height: $line-thickness;
        background-color: var(--font-color);
        opacity: 1;
        transition: opacity .01s ease-in-out calc(#{$animation-duration} / 2);
      }

      &::after, &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: $line-thickness;
        background-color: var(--font-color);
        left: 0;
      }
      &::after {
        top: 0;
        animation-duration: $animation-duration;
        animation-iteration-count: 1;
        animation-name: topCloseMenu;
      }
      &::before {
        bottom: 0;
        animation-duration: $animation-duration;
        animation-iteration-count: 1;
        animation-name: bottomCloseMenu;
      }

      &.open {
        div {
          opacity: 0;
          transition: opacity .01s ease-in-out calc(#{$animation-duration} / 2);
        }
        &::after {
          transform: translateY(calc((#{$button-height} / 2) - (#{$line-thickness} / 2))) rotate(45deg);
          animation-name: topOpenMenu;
        }
        &::before {
          transform: translateY(calc((-#{$button-height} / 2) + (#{$line-thickness} / 2))) rotate(-45deg);
          animation-name: bottomOpenMenu;
        }
      }
    }
  }
}

@keyframes topOpenMenu {
  0%   {
    transform: translateY(0) rotate(0);
  }
  50%  {
    transform: translateY(calc((#{$button-height} / 2) - (#{$line-thickness} / 2))) rotate(0);
  }
  100%  {
    transform: translateY(calc((#{$button-height} / 2) - (#{$line-thickness} / 2))) rotate(45deg);
  }
}

@keyframes topCloseMenu {
  0%   {
    transform: translateY(calc((#{$button-height} / 2) - (#{$line-thickness} / 2))) rotate(45deg);
  }
  50%  {
    transform: translateY(calc((#{$button-height} / 2) - (#{$line-thickness} / 2))) rotate(0);
  }
  100%  {
    transform: translateY(0) rotate(0);
  }
}

@keyframes bottomOpenMenu {
  0%   {
    transform: translateY(0) rotate(0);
  }
  50%  {
    transform: translateY(calc((-#{$button-height} / 2) + (#{$line-thickness} / 2))) rotate(0);
  }
  100%  {
    transform: translateY(calc((-#{$button-height} / 2) + (#{$line-thickness} / 2))) rotate(-45deg);
  }
}

@keyframes bottomCloseMenu {
  0%   {
    transform: translateY(calc((-#{$button-height} / 2) + (#{$line-thickness} / 2))) rotate(-45deg);
  }
  50%  {
    transform: translateY(calc((-#{$button-height} / 2) + (#{$line-thickness} / 2))) rotate(0);
  }
  100%  {
    transform: translateY(0) rotate(0);
  }
}