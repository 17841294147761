.judges {
  width: 100%;
  .judge-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 60px;
    gap: 20px;
  }

  .judge {
    border: 1px solid black;
    padding: 15px;
    border-radius: 20px;
    min-width: 250px;
    max-width: 312px;
    flex: 1;

    .judge-image {
      overflow: hidden;
      border-radius: 50%;
      display: inline-flex;
      margin-top: 10px;
      img {
        object-fit: cover;
      }
    }
  }

  .header-title {
    position: relative;
    margin-bottom: 30px;
    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 1px;
      background-color: var(--font-color);
      top: calc(50% - 1px);
      left: 0;
    }
    h2 {
      background-color: var(--background-color);
      display: inline;
      padding: 0 20px;
      position: relative;
      font-size: min(2.2em, 4.6vw);
      z-index: 1;
      &::after, &::before {
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        background-color: var(--font-color);
        border-radius: 50%;
        top: calc(50% - 4px);
      }
      &::before {
        left: 0;
      }
      &::after {
        right: 0;
      }
    }
  }
}