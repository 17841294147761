@import 'styles/variables';

.nav-link {
  text-decoration: none;
  color: var(--font-color);
  &:visited {
    color: var(--font-color);
  }
  &.active {
    font-weight: 600;
  }
}