@import 'styles/mixins';
@import 'styles/variables';

$topBar: 77px; // 77px when fixed

header {
  .top-bar {
    height: $topBar;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: var(--box-shadow);
    width: 100%;
    z-index: 10;
    position: relative;
    left: 0;
    top: 0;
    background-color: var(--hero-color);
    padding: 0;
    --pad-min: calc(calc(calc(100vw - var(--page-width)) / 2) + 70px);
    padding-left: max(var(--pad-min), 70px);
    padding-right: max(var(--pad-min), 70px);
    @include allPhones {
      padding-left: 40px;
      padding-right: 40px;
    }
    
    .logo, .logo a {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
    }
  }

  .hero {
    margin-top: 0;
    text-align: center;
    width: 100%;
    background: url('/assets/images/skyline.avif');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    min-height: min(635px, 100vw * 0.53);
    padding: 0 var(--content-padding);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include tablet {
      min-height: calc(80vh - #{$topBar});
    }
    @include allPhones {
      min-height: min(calc(95vh - #{$topBar}), 640px);
      text-align: center;
    }
  
    h1 {
      font-weight: 800;
      line-height: 100%;
      font-size: min(6.6vw, 4.5rem);
      // margin-bottom: 9%;
      text-align: center;
      
      .subtitle {
        display: block;
        font-size: 0.5em;
      }
      
      .brands {
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      @include tablet {
        // margin-bottom: 25vh;
        font-size: 2.5rem;
        .brands {
          margin-top: 10px;
          display: block;
          height: min-content;
        }
      }
      @include allPhones {
        // margin-bottom: 50%;
        .brands {
          margin-top: 10px;
          display: block;
          height: min-content;
        }
      }
      .hero-brands {
        margin: 0 10px;
        width: auto;
        height: 25px;
        @include smallPhone {
          max-height: 20px;
        }
        &.isolated {
          display: block;
          text-align: center;
          width: 100%;
          margin: 0;
          margin-bottom: 20px;
          height: 40px;
          @include tablet {
            margin-top: 30px;
            margin-bottom: 10px;
          }
          @include smallPhone {
            margin-top: 30px;
            margin-bottom: 10px;
            max-height: 35px;
          }
        }
      }
    }
  
    .dates {
      font-size: min(1.2rem, 1.6vw);
      font-family: var(--time-font-family);
      margin-bottom: 24px;
      display: block;
      text-align: center;
      @include allPhones {
        font-size: 1rem;
      }
    }
  
    .bg-layer__first {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
      background: linear-gradient(90deg, rgba(231, 98, 39, 0.5) 0%, rgba(33, 98, 238, 0.5) 100%);
    }
    .bg-layer__second {
      position: absolute;
      width: 100%;
      height: 111%;
      left: 0;
      top: 0;
      z-index: 2;
      background: url('/assets/images/poly-bg.svg');
      background-position: center;
      transform: translateY(-10%);
      background-repeat: no-repeat;
      background-size: cover;
    }
  
    .content {
      position: relative;
      color: var(--hero-color);
      z-index: 3;
      max-width: var(--page-width);
      width: 100%;
      margin: auto;
    }
  }
}
