@import 'styles/mixins';
@import 'styles/variables';

.faq-element {
  width: 100%;
  margin: 5px 0;
  border-radius: 10px;
  padding: 20px 30px;
  font-size: 1em;
  cursor: pointer;
  
  &.hidden {
    border: 1px solid rgb(223, 223, 223);
    background: #FFFFFF;
    transition: background-color .3s ease-in-out, border-color .3s ease-in-out;
  }
  &.visible {
    border: 1px solid #2162ee;
    background-color: #f7f7f7;
    transition: background-color .3s ease-in-out .3s, border-color .3s ease-in-out .3s;
  }
  
  .question {
    text-align: left;
    width: 100%;
    display: block;
    display: flex;
    justify-content: space-between;
  }

  .faq-answer {
    overflow: hidden;
    &.visible {
      max-height: 600px;
      opacity: 1;
      transition: max-height .3s ease-in-out .35s, opacity .3s ease-in-out .35s;
    }
    &.hidden {
      max-height: 0;
      opacity: 0;
      transition: max-height .3s ease-in-out, opacity .3s ease-in-out;
    }
    
    .content {
      font-size: 0.8em;
      text-align: left;
      margin-top: 20px;
      color: #666666;
      line-height: 125%;
    }
  }
}