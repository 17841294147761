@import 'styles/mixins';
@import 'styles/variables';

.brands-wall {
  text-align: center;
  width: 100%;

  .header-title {
    position: relative;
    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 1px;
      background-color: var(--font-color);
      top: calc(50% - 1px);
      left: 0;
    }
    h2 {
      background-color: var(--background-color);
      display: inline;
      padding: 0 20px;
      position: relative;
      font-size: min(2.2em, 4.6vw);
      z-index: 1;
      &::after, &::before {
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        background-color: var(--font-color);
        border-radius: 50%;
        top: calc(50% - 4px);
      }
      &::before {
        left: 0;
      }
      &::after {
        right: 0;
      }
    }
  }

  .brands-list {
    margin: 25px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: 30px;
    flex-wrap: wrap;
    
    .brand {
      width: 300px;
      border: 1px solid var(--font-color);
      border-radius: 20px;
      height: 130px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
      cursor: pointer;
      position: relative;
      transition: border-color .1s ease-in-out, box-shadow .1s ease-in-out;
      &.no-link {
        cursor: default;
      }
      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 20px;
        left: 0;
        top: 0;
        border: 2px solid transparent;
        transition: border-color .1s ease-in-out, box-shadow .1s ease-in-out;
      }
      &:hover {
        border-color: #2162ee;
        box-shadow: inset 0px 0px 9px rgb(33 98 238 / 70%);
        &::before {
          box-shadow: 0px 0px 9px rgb(33 98 238 / 70%);
          border: 1.5px solid #2162ee;
        }
      }

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
  }
}
