.faq {
  background-image: url("/assets/images/faq-bg.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100vh;
  h1 {
    color: #2162ee;
    font-size: 2.5em;
    margin-bottom: 0;
  }
  .sub-text {
    font-size: 0.8rem;
    margin-bottom: 40px;
  }
}